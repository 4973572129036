export default function formatPrice(
  price: number,
  options?: Intl.NumberFormatOptions
): string {
  return (
    Intl.NumberFormat("en", {
      style: "currency",
      currency: "USD",
      currencyDisplay: "code",
      ...options,
    }).format(price / 100) + " USD"
  );
}
