import styled from "styled-components";
import media from "styled-media-query";

export const LogoContainer = styled.div`
  position: relative;

  svg {
    max-width: 100%;
  }

  width: 4rem;
  height: 4rem;
  img {
    width: 4rem;
    height: 4rem;
  }

  ${media.greaterThan("medium")`
    width: 5.6rem;
    height: 5.6rem;

    img{
      width: 5.6rem;
      height: 5.6rem;
    }
  `}
`;
