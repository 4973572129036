import styled, { css } from "styled-components";

export const ModalContent = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    z-index: ${theme.layers.alwaysOnTop};
    padding: ${theme.spacings.medium};
    border: 1px solid #f2f2f2;
    width: 100%;
    max-width: 343px;
    border-radius: ${theme.border.radius};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);

    > span {
      display: block;
      font-size: 2.4rem;
      line-height: ${theme.spacings.large};
      color: ${theme.colors.black};
    }

    > p {
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.spacings.small};
      letter-spacing: 0.0025em;
      color: #363636;
      margin-top: 0.4rem;
    }
  `}
`;

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    visibility: visible;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
    visibility: hidden;
  `,
};

type WrapperProps = {
  isOpen?: boolean;
};

export const ModalWrapper = styled.div<WrapperProps>`
  ${({ theme, isOpen }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.alwaysOnTop};

    ${ModalContent} {
      transition: transform 0.2s ease-in, opacity ${theme.transition.default};
    }

    ${isOpen && wrapperModifiers.open()}
    ${!isOpen && wrapperModifiers.close()}
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    margin-top: ${theme.spacings.medium};
  `}
`;

type ButtonProps = {
  isOutline?: boolean;
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, isOutline }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.small};
    letter-spacing: 0.0025em;
    border: 1px solid ${theme.colors.red};
    border-radius: ${theme.border.radius};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.98);
    }

    background-color: ${theme.colors.red};
    color: ${theme.colors.white};

    ${isOutline &&
    css`
      color: ${theme.colors.red};
      background-color: ${theme.colors.white};
    `}
  `}
`;
