import axios from "axios";
import { NextApiRequest } from "next";

export const api = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const resolveRequestHost = (request: NextApiRequest) => {
  const host = request.headers.host;
  if (!host || typeof host === undefined) {
    return {
      error: {
        status: 412,
        message: "Missing request host header",
      },
    };
  }
  return {
    host,
  };
};
