export default function s3ToCloudFront(s3Url) {
  const s3Prefixes = process.env.NEXT_PUBLIC_AWS_S3_URL;
  const cloudFrontPrefix = process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_URL;

  if (!s3Prefixes || !cloudFrontPrefix || !s3Url) {
    return s3Url;
  }

  const s3PrefixUrls = s3Prefixes.split(",");
  let newUrl = s3Url;

  for (const s3Prefix of s3PrefixUrls) {
    newUrl = newUrl.replace(s3Prefix, cloudFrontPrefix);
  }

  return newUrl;
}
