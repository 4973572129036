import styled, { css } from "styled-components";

export const MobileHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: ${theme.spacings.xsmall};
    margin-bottom: ${theme.spacings.medium};
  `}
`;

export const MobileLoggedContent = styled.div`
  display: flex;
  align-items: center;
`;

export const MobilePicture = styled.a`
  position: relative;
  height: 3.2rem;
  width: 3.2rem;
  margin-right: 1.2rem;

  img {
    border-radius: 50%;
  }
`;

export const MobileUserName = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.spacings.small};
  `}
`;

export const MobileCloseButton = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dbdbdb;
  padding: 0.8rem;
  border-radius: 6px;
  cursor: pointer;
`;

export const MobileMenuLink = styled.a`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.font.sizes.xxxlarge};
    line-height: ${theme.spacings.xlarge};
    text-decoration: none;
    color: ${theme.colors.black};
    text-decoration: none;
    margin-bottom: ${theme.spacings.small};

    transition: filter ${theme.transition.default};
    :hover {
      filter: brightness(0.8);
    }
  `}
`;

type MobileMenuProps = {
  isOpen: boolean;
};

export const MobileMenu = styled.nav<MobileMenuProps>`
  ${({ theme, isOpen }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.xsmall};
    color: ${theme.colors.black};
    background: ${theme.colors.white};
    position: fixed;
    z-index: ${theme.layers.menu};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? "all" : "none"};
  `}
`;
