import styled, { css } from "styled-components";

type ImageProps = {
  layout?: string;
  objectFit?: string;
};

export const Image = styled.img<ImageProps>`
  ${({ layout, objectFit }) => css`
    ${layout === "fill" &&
    `position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    `}
    ${objectFit && `object-fit: ${objectFit}`}
  `}
`;
