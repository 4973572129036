import * as S from "./styles";

type ConfirmationModalProps = {
  isOpen: boolean;
  title: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  className?: string;
};

const ConfirmationModal = ({
  isOpen,
  title,
  description,
  onConfirm,
  onCancel,
  className = "confirmation-modal",
}: ConfirmationModalProps) => (
  <S.ModalWrapper isOpen={isOpen} className={className}>
    <S.ModalContent aria-hidden={!isOpen} className="modal__content">
      <span>{title}</span>
      {description && <p>{description}</p>}
      <S.ButtonsContainer className="confirmation-modal_buttons-container">
        <S.Button
          className="confirmation-modal_confirm-button"
          onClick={onConfirm}
        >{`Yes, I'm sure`}</S.Button>
        <S.Button
          className="confirmation-modal_cancel-button"
          onClick={onCancel}
          isOutline
        >
          Cancel
        </S.Button>
      </S.ButtonsContainer>
    </S.ModalContent>
  </S.ModalWrapper>
);

export default ConfirmationModal;
