import Image from "components/Image";
import Link from "next/link";
import s3ToCloudFront from "utils/s3ToCloudFront";

import DefaultLogo from "./ClemsonLogo";
import * as S from "./styles";

type LogoProps = {
  logoUrl?: string;
};

const Logo = ({ logoUrl }: LogoProps) => (
  <Link href="/">
    <a className="logo__link">
      <S.LogoContainer className="logo__logo-container">
        {logoUrl && (
          <Image
            layout="fill"
            objectFit="contain"
            src={s3ToCloudFront(logoUrl)}
            alt="Organization logo"
            className="logo__image"
          />
        )}
        {!logoUrl && <DefaultLogo />}
      </S.LogoContainer>
    </a>
  </Link>
);

export default Logo;
