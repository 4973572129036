import { LatestUserNotifications } from "domain/notification/types";
import { api } from "lib/api";
import useSWR from "swr";

const fetcher = (url: string) => api.get(url).then((res) => res.data);

const useNotification = (
  shouldFetch: boolean,
  initialData: { notifications: LatestUserNotifications[]; unread: number }
) => {
  const { data, error } = useSWR(
    shouldFetch ? "/my/latest-notifications" : null,
    fetcher,
    {
      initialData,
      refreshInterval: 3000,
      revalidateOnMount: true,
    }
  );

  return {
    newNotifications: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useNotification;
