import { useEffect } from "react";

const useHideScroll = (isOpen: boolean | undefined) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("hide-scroll");
      return;
    }

    document.body.classList.remove("hide-scroll");
  }, [isOpen]);
};

export default useHideScroll;
