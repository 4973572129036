/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Search } from "@styled-icons/boxicons-regular";
import Loading from "components/LoadingIndicator";
import { useRouter } from "next/router";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { HeaderColor } from "..";
import * as S from "./styles";

const TAG_NAME = "searchTerm";

type SearchBoxFormValues = {
  [TAG_NAME]: string;
};

export type SearchBoxProps = InputHTMLAttributes<HTMLInputElement> & {
  mobileColor?: HeaderColor;
  desktopColor?: HeaderColor;
};

const SearchBox = ({
  mobileColor = "black",
  desktopColor = "black",
}: SearchBoxProps) => {
  const { query, push, pathname } = useRouter();
  const [loading, setLoading] = useState(false);
  const isMarketplacePage = pathname === "/marketplace";
  const queryValue = (query[TAG_NAME] as string) || "";

  const { register, handleSubmit, getValues, setValue } =
    useForm<SearchBoxFormValues>({
      defaultValues: {
        [TAG_NAME]: queryValue,
      },
    });

  useEffect(() => {
    const formValue = getValues(TAG_NAME);
    if (queryValue !== formValue) {
      setValue(TAG_NAME, queryValue);
    }
  }, [queryValue]);

  const onSubmit = handleSubmit((data) => {
    const value = data[TAG_NAME];

    if (!value && !isMarketplacePage) {
      return;
    }

    const restQuery = isMarketplacePage ? query : {};
    if (!value) {
      const { [TAG_NAME]: remove, ...rest } = restQuery;
      push({ query: rest }, undefined, {
        shallow: isMarketplacePage,
        scroll: false,
      });
      return;
    }

    push(
      {
        pathname: "/marketplace",
        query: { ...restQuery, [TAG_NAME]: value },
      },
      undefined,
      {
        shallow: isMarketplacePage,
        scroll: false,
      }
    );
  });

  return (
    <S.InputWrapper
      mobileColor={mobileColor}
      desktopColor={desktopColor}
      onSubmit={onSubmit}
      className="search-box"
    >
      <S.Icon className="search-box__icon">
        <Search className="search-box__search" />
      </S.Icon>
      <S.Input
        type="text"
        placeholder="Search"
        {...register(TAG_NAME)}
        className="search-box__input"
      />
    </S.InputWrapper>
  );
};

export default SearchBox;
