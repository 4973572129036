import getConfig from "next/config";
import { default as NextImage } from "next/image";
import s3ToCloudFront from "utils/s3ToCloudFront";

import * as S from "./styles";

const Image = ({ src, objectFit, layout, ...rest }) => {
  const isSVG = src.includes(".svg");
  const { hostname } = new URL(src);

  const {
    publicRuntimeConfig: {
      images: { domains },
    },
  } = getConfig();

  const unsupportedDomain = !domains.includes(hostname);

  if (isSVG || unsupportedDomain) {
    // eslint-disable-next-line @next/next/no-img-element
    return (
      <S.Image
        loading="lazy"
        src={s3ToCloudFront(src)}
        layout={layout}
        objectFit={objectFit}
        {...rest}
      />
    );
  }

  return (
    <NextImage
      loading="lazy"
      src={s3ToCloudFront(src)}
      objectFit={objectFit}
      layout={layout}
      {...rest}
    />
  );
};

export default Image;
