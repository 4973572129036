import styled, { css, DefaultTheme } from "styled-components";
import media from "styled-media-query";

import { ButtonProps } from ".";

export type WrapperProps = {
  hasIcon: boolean;
  primary: boolean;
  secondary: boolean;
} & Pick<ButtonProps, "size" | "fullWidth" | "buttonType">;

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    font-size: ${theme.font.sizes.xsmall};
  `,
  medium: (theme: DefaultTheme) => css`
    font-size: ${theme.font.sizes.xlarge};
    line-height: ${theme.spacings.small};
    padding: 2rem;
  `,
  large: (theme: DefaultTheme) => css`
    font-size: ${theme.font.sizes.xlarge};
    line-height: ${theme.spacings.small};
    padding: 2rem;
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  withIcon: (theme: DefaultTheme) => css`
    svg {
      width: 1.5rem;
      & + span {
        margin-left: ${theme.spacings.xxsmall};
      }
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    color: ${theme.colors.red};
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.red};
  `,
  primary: (theme: DefaultTheme) => css`
    color: ${theme.colors.white};
    background: ${theme.colors.red};
  `,
  disabled: () => css`
    &:disabled {
      cursor: not-allowed;
      filter: saturate(30%);
    }
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, size, fullWidth, hasIcon, disabled, primary, secondary }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    font-family: ${theme.font.family};
    border-radius: ${theme.border.radius};
    border: 1px solid ${theme.colors.red};
    ${!!size && wrapperModifiers[size](theme)};
    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${!!hasIcon && wrapperModifiers.withIcon(theme)};
    ${disabled && wrapperModifiers.disabled()};
    ${primary && wrapperModifiers.primary(theme)};
    ${secondary && wrapperModifiers.secondary(theme)};

    ${media.lessThan("medium")`{
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
      padding: ${theme.spacings.xsmall};
      line-height: ${theme.spacings.xsmall};
    `}
  `}
`;
