import { useUser } from "@auth0/nextjs-auth0/client";
import { LogIn, User } from "@styled-icons/boxicons-regular";
import ConfirmationModal from "components/ConfirmationModal";
import LogoutIcon from "components/Icons/LogoutIcon";
import ProfileIcon from "components/Icons/ProfileIcon";
import WalletIcon from "components/Icons/WalletIcon";
import Image from "components/Image";
import useHideScroll from "hooks/useHideScroll";
import { useWindowSize } from "hooks/useWindowSize";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import formatPrice from "utils/format-price";
import s3ToCloudFront from "utils/s3ToCloudFront";

import * as S from "./styles";

const UserDropdown = () => {
  const { push, asPath } = useRouter();
  const { user } = useUser();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  useHideScroll(isDropDownOpen);
  const { isMobile } = useWindowSize();

  const handleLogout = async () => {
    push("/api/auth/logout");
  };

  return (
    <S.Wrapper isOpen={isDropDownOpen} className="user-dropdown">
      <S.Title
        isOpen={isDropDownOpen}
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        className="user-dropdown__title"
      >
        <S.IconWrapper className="user-dropdown__icon-wrapper">
          {user?.picture && (
            <Image
              src={s3ToCloudFront(user.picture)}
              alt={`${user.username} photo`}
              layout="fill"
              objectFit="cover"
              className="user-dropdown__image"
            />
          )}
          {!user?.picture && (
            <User size="16" className="user-dropdown__user-icon" />
          )}
        </S.IconWrapper>
      </S.Title>

      <S.Content
        isOpen={isDropDownOpen}
        isMobile={isMobile}
        aria-hidden={!isDropDownOpen}
        className="user-profile__content"
      >
        {!user && (
          <S.Nav className="user-dropdown__nav">
            <S.Link href={`/api/auth/login?returnTo=${asPath}`}>
              <LogIn className="user-dropdown__login" />
              <span>Sign in/Sign up</span>
            </S.Link>
          </S.Nav>
        )}
        {user && (
          <S.Nav className="user-dropdown__nav">
            <S.UsernameText className="user-dropdown__username-text">
              {user.user_metadata.username}
            </S.UsernameText>
            <S.CreditBalance className="user-dropdown__credit-balance">
              <S.BalanceTile className="user-dropdown__balance-tile">
                Your total balance
              </S.BalanceTile>
              <S.BalanceText className="user-dropdown__balance-text">
                <strong>
                  {formatPrice(
                    Number.parseFloat(user.totalCredits as string) || 0,
                    {
                      currencyDisplay: "symbol",
                    }
                  )}
                </strong>
              </S.BalanceText>
              <S.BalanceTile className="user-dropdown__balance-tile">
                Your available balance
              </S.BalanceTile>
              <S.BalanceText className="user-dropdown__balance-text">
                <strong>
                  {formatPrice(user.availableCredits || 0, {
                    currencyDisplay: "symbol",
                  })}
                </strong>
              </S.BalanceText>
              <Link href="/transfer" passHref>
                <S.CashOutButton className="user-dropdown__cashout-button">
                  <WalletIcon className="user-dropdown__wallet-icon" />
                  <p>Cash out your available balance</p>
                </S.CashOutButton>
              </Link>
            </S.CreditBalance>
            <Link href={`/profile/${user.user_metadata.username}`} passHref>
              <S.Link>
                <ProfileIcon className="user-dropdown__profile-icon" />
                <span>My Profile & NFTs</span>
              </S.Link>
            </Link>
            <S.Link
              role="button"
              title="Log out"
              onClick={() => setIsLogoutModalOpen(true)}
              className="user-dropdown__link"
            >
              <LogoutIcon className="user-dropdown__logout-icon" />
              <span>Log out</span>
            </S.Link>
          </S.Nav>
        )}
      </S.Content>
      <LogoutConfirmationModal
        {...{ isLogoutModalOpen, setIsLogoutModalOpen, handleLogout }}
      />
      <S.Overlay
        aria-hidden={!isDropDownOpen}
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        className="user-dropdown__overlay"
      />
    </S.Wrapper>
  );
};

export default UserDropdown;

const LogoutConfirmationModal = ({
  isLogoutModalOpen,
  handleLogout,
  setIsLogoutModalOpen,
}) => (
  <ConfirmationModal
    className="logout-confirmation-modal"
    isOpen={isLogoutModalOpen}
    onConfirm={handleLogout}
    onCancel={() => setIsLogoutModalOpen(false)}
    title="Are you sure?"
  />
);
