import useHideScroll from "hooks/useHideScroll";
import { useEffect, useState } from "react";

import * as S from "./styles";

export type DropdownProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  externalState?: boolean;
  onOverlayClick?: () => void;
};

const Dropdown = ({
  title,
  children,
  externalState,
  onOverlayClick,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(externalState || false);
  useHideScroll(isOpen);

  useEffect(() => {
    if (externalState !== undefined) {
      setIsOpen(externalState);
    }
  }, [externalState]);

  const handleOverlayClick = () => {
    setIsOpen(!isOpen);
    onOverlayClick && onOverlayClick();
  };

  return (
    <S.Wrapper isOpen={isOpen} className="dropdown">
      <S.Title
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown__title"
      >
        {title}
      </S.Title>

      <S.Content
        isOpen={isOpen}
        aria-hidden={!isOpen}
        className="dropdown__content"
      >
        {children}
      </S.Content>
      <S.Overlay
        aria-hidden={!isOpen}
        onClick={handleOverlayClick}
        className="dropdown__overlay"
      />
    </S.Wrapper>
  );
};

export default Dropdown;
