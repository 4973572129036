import { ExclamationCircleFill, TagFill } from "@styled-icons/bootstrap";
import { CardGiftcard, Gavel } from "@styled-icons/material-outlined";
import { CheckboxBlankCircle as UnreadCircle } from "@styled-icons/remix-fill/CheckboxBlankCircle";
import { MoneyDollarCircle } from "@styled-icons/remix-fill/MoneyDollarCircle";
import { Notification } from "domain/notification/types";
import { DateTime, Interval } from "luxon";

import * as S from "./styles";

interface NotificationListProps {
  notifications: Notification[];
}

const typeToIcon = {
  outbid: <Gavel />,
  purchase: <TagFill />,
  auction_won: <Gavel />,
  auction_checkout_expired: <Gavel />,
  auction_expiration_alert: <Gavel />,
  auction_no_payment: <Gavel />,
  auction_lost: <Gavel />,
  auction_ended: <Gavel />,
  nft_drop: <ExclamationCircleFill />,
  sell: <TagFill />,
  chargeback_window_completed_purchase: <MoneyDollarCircle />,
  chargeback_window_completed_sale: <MoneyDollarCircle />,
  wire_transfer_complete: <MoneyDollarCircle />,
  pack_claimed: <CardGiftcard />,
  pack_delivered: <CardGiftcard />,
  pack_item_visible: <CardGiftcard />,
};

export const NotificationList: React.FunctionComponent<
  NotificationListProps
> = ({ notifications }) => {
  return (
    <>
      {notifications.length > 0 &&
        notifications.map((notification) => (
          <NotificationListItem
            {...notification}
            key={notification._id.toString()}
          />
        ))}
      {notifications.length < 1 && (
        <S.EmptyNotificationList className="notifications__empty">
          There are no notifications
        </S.EmptyNotificationList>
      )}
    </>
  );
};

export const NotificationListItem: React.FunctionComponent<Notification> = (
  notification
) => {
  const PROTOCOL = (process.env.PROD && "https://") || "http://";

  return (
    <S.NotificationLink
      className="notifications__notification-link"
      href={
        notification.url ? `${PROTOCOL}${notification.url}` : "/notifications"
      }
    >
      <S.NotificationIconContainer className="notifications__icon">
        {typeToIcon[notification.type]}
      </S.NotificationIconContainer>
      <S.NotificationContent className="notifications__content">
        <S.NotificationText className="notifications__text">
          {notification.content}
        </S.NotificationText>
        <NotificationTime date={notification.date} />
      </S.NotificationContent>
      {!notification.read && (
        <S.UnreadNotificationSign className="notifications__icon-sign">
          <UnreadCircle
            height={12}
            width={12}
            className="notifications__unread-circle"
          />
        </S.UnreadNotificationSign>
      )}
    </S.NotificationLink>
  );
};

interface TimespanComponentProps {
  date: Date;
}

const NotificationTime = ({ date }: TimespanComponentProps) => {
  const { days, hours, minutes } = Interval.fromDateTimes(
    DateTime.fromJSDate(new Date(date)),
    DateTime.now()
  )
    .toDuration()
    .shiftTo("day", "hour", "minute")
    .toObject();

  return (
    <S.TimespanText className="notifications__timespan-text">
      {!days &&
        !hours &&
        !!minutes &&
        `${parseInt(minutes.toString())} min(s) ago`}
      {!days && !!hours && `${parseInt(hours.toString())} hour(s) ago`}
      {!!days && `${parseInt(days.toString())} day(s) ago`}
    </S.TimespanText>
  );
};
