import styled, { css } from "styled-components";

type DropdownProps = {
  isOpen?: boolean;
};

export const Title = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    cursor: pointer;
    color: ${theme.colors.white};
    position: relative;
    display: flex;
    align-items: center;
    z-index: ${isOpen ? theme.layers.modal : theme.layers.menu};
  `}
`;

export const Content = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    cursor: auto;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    margin-top: ${theme.spacings.small};
    position: absolute;
    right: 0;
    border-radius: ${theme.border.radius};
    z-index: ${isOpen ? theme.layers.modal : theme.layers.menu};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

    a:last-child {
      border-radius: ${theme.border.radius};
    }
  `}
`;

export const Overlay = styled.div<DropdownProps>`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.overlay};
  `}
`;

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  `,
};

export const Wrapper = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    position: relative;
    width: max-content;

    ${Content},
    ${Overlay} {
      transition: transform 0.2s ease-in, opacity ${theme.transition.default};

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`;
