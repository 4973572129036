import { useEffect, useRef, useState } from "react";
import { defaultBreakpoints } from "styled-media-query";

const medium = parseInt(defaultBreakpoints.medium.replace("px", ""));
const large = parseInt(defaultBreakpoints.large.replace("px", ""));

export const useWindowSize = () => {
  const [isSizeLoaded, setIsSizeLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const mount = useRef(false);

  useEffect(() => {
    if (mount.current) {
      setIsSizeLoaded(true);
    }
  }, [isMobile]);

  function handleWindowSizeChange() {
    mount.current = true;
    setIsMobile(window.innerWidth <= medium);
    setIsLaptop(window.innerWidth <= large);
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { isMobile, isLaptop, isSizeLoaded };
};
