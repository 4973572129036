import styled, { css } from "styled-components";
import media from "styled-media-query";

import { getBorderColor, getSearchBoxContentColor } from "../utils";
import { SearchBoxProps } from ".";

export const Input = styled.input`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    padding-left: ${theme.spacings.xsmall};
    background: transparent;
    border: 0;
    outline: none;
    width: 100%;
  `}
`;

export const Icon = styled.div`
  ${() => css`
    display: flex;

    & > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  `}
`;

type InputWrapperProps = Pick<SearchBoxProps, "mobileColor" | "desktopColor">;

export const InputWrapper = styled.form<InputWrapperProps>`
  ${({ theme, mobileColor, desktopColor }) => css`
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    max-width: 340px;
    width: 100%;
    height: 3.5rem;

    border: 1px solid ${getBorderColor(theme, mobileColor)}};
    ${Input}{
      color: ${theme.colors[mobileColor!]};
    }

    &:focus-within {
      border: 3px solid ${getBorderColor(theme, desktopColor)};
    }

    ${Icon},
    ${Input}::placeholder {
      color: ${getSearchBoxContentColor(theme, mobileColor)};
    }

    ${media.greaterThan("medium")`
      margin-left: 4rem;
      height: 4rem;

      border: 1px solid ${getBorderColor(theme, desktopColor)}};
      ${Input}{
        color: ${theme.colors[desktopColor!]};
      }

      ${Icon},
      ${Input}::placeholder {
        color: ${getSearchBoxContentColor(theme, desktopColor)};
      }
    `}
  `}
`;

export const Wrapper = styled.div``;
