import Button from "components/Button";
import * as UserDropdownStyles from "components/UserDropdown/styles";
import styled, { css } from "styled-components";
import media from "styled-media-query";

import { HeaderProps } from ".";
import { getBorderColor, getDesktopLinkColor } from "./utils";

export const IconWrapper = styled.button.attrs({
  className: "header__icon-wrapper",
})`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.xsmall};
    background-color: transparent;
    cursor: pointer;
    position: relative;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: ${theme.border.radius};

    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.9);
    }

    ${media.greaterThan("medium")`
      margin-left: ${theme.spacings.medium};
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
    `}
  `}
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DesktopNav = styled.nav`
  display: none;
  align-items: center;

  ${media.greaterThan("large")`
    display: flex;
  `}
`;

export const MobileNav = styled.nav`
  display: flex;
  align-items: center;

  ${media.greaterThan("large")`
    display: none;
  `}
`;

type MenuLinkProps = Pick<HeaderProps, "mobileColor" | "desktopColor"> & {
  isSelected: boolean;
};

export const MenuLink = styled.a<MenuLinkProps>`
  ${({ theme, isSelected, desktopColor, mobileColor }) => css`
    font-weight: ${isSelected ? 600 : 500};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.spacings.small};
    margin-left: ${theme.spacings.medium};
    text-decoration: none;
    letter-spacing: 0.0015em;
    color: ${getDesktopLinkColor(theme, mobileColor, isSelected)};

    transition: filter ${theme.transition.default};
    :hover {
      filter: brightness(0.8);
    }

    ${media.greaterThan("medium")`
      color: ${getDesktopLinkColor(theme, desktopColor, isSelected)};
    `}
  `}
`;

type WrapperProps = Pick<HeaderProps, "mobileColor" | "desktopColor"> & {
  isOpen: boolean;
};

export const Wrapper = styled.header<WrapperProps>`
  ${({ theme, isOpen, mobileColor, desktopColor }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${isOpen
      ? theme.layers.menu
      : `calc(${theme.layers.menu} - 1)`} !important;
    padding: ${theme.spacings.xsmall} 0;
    margin: 0 ${theme.spacings.large};
    color: ${theme.colors[mobileColor!]};
    border-bottom: 1px solid ${getBorderColor(theme, mobileColor)};

    ${UserDropdownStyles.IconWrapper},
    ${IconWrapper} {
      border: 1px solid ${getBorderColor(theme, mobileColor)};
      color: ${theme.colors[mobileColor!]};

      svg {
        color: ${theme.colors[mobileColor!]};
      }
    }

    ${media.greaterThan("medium")`
      margin: 0 4rem;
      padding: 2rem 0;
      color: ${theme.colors[desktopColor!]};

      ${UserDropdownStyles.IconWrapper},
      ${IconWrapper} {
        border: 1px solid  ${getBorderColor(theme, desktopColor)};
        color: ${theme.colors[desktopColor!]};

        svg {
          color: ${theme.colors[desktopColor!]};
        }
      }
    `}
  `}
`;

export const Badge = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${theme.colors.red};
    position: absolute;
    top: -5px;
    right: -5px;
    height: 15px;
    width: 15px;

    ${media.greaterThan("medium")`
      height: 20px;
      width: 20px;
    `}
  `}
`;

export const MobileSearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MobileSearchBoxCancel = styled.span`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.spacings.small};
    letter-spacing: 0.0025em;
    margin-left: 1.2rem;
  `}
`;

export const DropdownContentWrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    z-index: ${theme.layers.alwaysOnTop};
    width: 100vw;
    height: 100vh;

    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    background-color: ${theme.colors.white};

    ${media.greaterThan("medium")`
      border-radius: ${theme.border.radius};
      position: static;
      z-index: ${theme.layers.base};
      width: 39rem;
      max-height: 70vh;
  `}

    ::-webkit-scrollbar {
      background: transparent;
      width: 1rem;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 2rem;
    }
  `}
`;

export const DropdownHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  border-bottom: 1px solid #dbdbdb;

  ${media.greaterThan("medium")`
    border: none;
  `}
`;

export const NotificationTitle = styled.p`
  ${({ theme }) => css`
    font-size: 2.4rem;
    line-height: ${theme.spacings.large};
  `}
`;

export const NotificationLink = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    transition: background, color, ${theme.transition.default};

    &:hover {
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;

export const AllNotificationsLink = styled.a`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.red};
    cursor: pointer;
    text-decoration: none;
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.small};
  `}
`;

export const CloseButton = styled(Button)`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall};
    border: 1px solid #dbdbdb;
    color: ${theme.colors.black};
  `}
`;

export const NoNotification = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.font.sizes.medium};
    padding: 1.2rem;
  `}
`;
