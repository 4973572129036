import { useUser } from "@auth0/nextjs-auth0/client";
import { Bell } from "@styled-icons/boxicons-regular";
import { XMark } from "@styled-icons/heroicons-outline";
import Dropdown from "components/Dropdown";
import MediaMatch from "components/MediaMatch";
import { NotificationListItem } from "components/NotificationListing";
import { LatestUserNotifications } from "domain/notification/types";
import useNotification from "hooks/useNotification";
import { api } from "lib/api";
import Link from "next/link";
import { useCallback, useState } from "react";

import * as S from "./styles";

const Notifications = () => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState<boolean>();

  const { newNotifications }: { newNotifications: LatestUserNotifications } =
    useNotification(!!user, {
      notifications: [],
      unread: 0,
    });

  const setNotificationsAsRead = useCallback(
    () => api.get("my/mark-latest-notifications"),
    []
  );

  if (!user) {
    return null;
  }

  return (
    <Dropdown
      externalState={isOpen}
      onOverlayClick={() => setIsOpen(false)}
      title={
        <S.IconWrapper
          onClick={() => {
            setNotificationsAsRead();
            setIsOpen(!isOpen);
          }}
        >
          <Bell aria-label="Bell" size={"16"} className="notifications__bell" />
          {!!newNotifications?.unread && (
            <S.Badge className="notifications__badge">
              {newNotifications?.unread}
            </S.Badge>
          )}
        </S.IconWrapper>
      }
    >
      <S.DropdownContentWrapper className="notifications__dropdown-content-wrapper">
        {!!newNotifications?.notifications?.length && (
          <>
            <S.DropdownHeaderWrapper className="notifications__dropdown-header-wrapper">
              <S.NotificationTitle className="notifications__notification-title">
                Notifications
              </S.NotificationTitle>
              <MediaMatch
                className="notifications__media-match"
                greaterThan="medium"
                style={{ width: "unset" }}
              >
                <Link href={`/notifications`} passHref>
                  <S.AllNotificationsLink className="notifications__all-notifications-link">
                    See all
                  </S.AllNotificationsLink>
                </Link>
              </MediaMatch>
              <MediaMatch
                className="notifications__media-match"
                lessThan="medium"
                style={{ width: "unset" }}
              >
                <S.CloseButton
                  className="notifications__close-button"
                  size="small"
                  buttonType="secondary"
                  onClick={() => setIsOpen(false)}
                  icon={<XMark size={18} />}
                />
              </MediaMatch>
            </S.DropdownHeaderWrapper>
            {newNotifications.notifications.map((notification) => (
              <NotificationListItem
                key={notification._id.toString()}
                {...notification}
              />
            ))}
          </>
        )}
        {!newNotifications?.notifications?.length && (
          <>
            <S.DropdownHeaderWrapper className="notifications__dropdown-header-wrapper">
              <S.NotificationTitle className="notifications__notification-title">
                Notifications
              </S.NotificationTitle>
              <MediaMatch
                className="notifications__media-match"
                lessThan="medium"
                style={{ width: "unset" }}
              >
                <S.CloseButton
                  className="notifications__close-button"
                  size="small"
                  buttonType="secondary"
                  onClick={() => setIsOpen(false)}
                  icon={<XMark size={18} />}
                />
              </MediaMatch>
            </S.DropdownHeaderWrapper>
            <S.NoNotification className="notifications__no-notification">
              There are no notifications
            </S.NoNotification>
          </>
        )}
      </S.DropdownContentWrapper>
    </Dropdown>
  );
};

export default Notifications;
