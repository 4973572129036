import styled, { css } from "styled-components";
import media from "styled-media-query";

type DropdownProps = {
  isOpen?: boolean;
  isMobile?: boolean;
};

export const Title = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    cursor: pointer;
    color: ${theme.colors.white};
    position: relative;
    display: flex;
    align-items: center;
    z-index: ${isOpen ? theme.layers.modal : theme.layers.menu};
  `}
`;

export const Content = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    cursor: auto;
    display: flex;
    flex-: column;
    background: ${theme.colors.white};
    border-radius: ${theme.border.radius};
    color: ${theme.colors.black};
    margin-top: ${theme.spacings.small};
    position: absolute;
    z-index: ${isOpen ? theme.layers.modal : theme.layers.menu};

    ${media.greaterThan("small")`
      right: 0;
    `}

    ${media.lessThan("small")`
      left: 0;
    `}
  `}
`;

export const Overlay = styled.div`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.overlay};
  `}
`;
const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    visibility: visible;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
    visibility: hidden;
  `,
};

export const Wrapper = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    width: max-content;

    ${Content},
    ${Overlay} {
      transition: transform 0.2s ease-in, opacity ${theme.transition.default};

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 42rem;
    gap: 2rem;
    padding: ${theme.spacings.xsmall} ${theme.spacings.small}
      ${theme.spacings.xsmall};

    a:hover {
      background: ${theme.colors.white};
      color: ${theme.colors.red};
    }

    a:last-child {
      border-radius: ${theme.border.radius};
    }
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    transition: background, color, ${theme.transition.default};

    &:hover {
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
      > svg {
        stroke: ${theme.colors.red};
      }
    }

    > svg {
      width: 2.4rem;
      height: 2.4rem;
      stroke: ${theme.colors.black};
      transition: stroke, ${theme.transition.default};
    }

    > span {
      margin-left: ${theme.spacings.xxsmall};
    }
  `}
`;

export const CreditBalance = styled.div`
  ${({ theme }) => css`
    cursor: default;
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.colors.gray};
    border-radius: 5px;
    padding: ${theme.spacings.xsmall};
  `}
`;

export const UsernameText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: 700;
  `}
`;

export const BalanceTile = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGray};
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.font.sizes.xxlarge};
  `}
`;

export const BalanceText = styled.span`
  ${({ theme }) => css`
    margin-bottom: 2rem;
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.xxlarge};
  `}
`;

export const CashOutButton = styled.a`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall};
    background-color: transparent;
    border: 1px solid ${theme.colors.gray};
    border-radius: 5px;
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.font.sizes.xxlarge};
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: ${theme.colors.black};
    transition: background, color, ${theme.transition.default};

    &:hover {
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
      > svg {
        stroke: ${theme.colors.red};
      }
    }

    > svg {
      width: 2.4rem;
      height: 2.4rem;
      stroke: ${theme.colors.black};
      transition: stroke, ${theme.transition.default};
    }
  `}
`;

export const IconWrapper = styled.button`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.xsmall};
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    height: 3.2rem;
    width: 3.2rem;
    border: 1px solid #dbdbdb;
    border-radius: ${theme.border.radius};
    background-color: transparent;

    img {
      border-radius: ${theme.border.radius};
    }

    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.9);
    }

    ${media.greaterThan("medium")`
      margin-left: ${theme.spacings.medium};
      height: 4rem;
      width: 4rem;
      border-radius: 50%;

      img{
        border-radius: 50%;
      }
    `}
  `}
`;
