import { Menu, Search } from "@styled-icons/boxicons-regular";
import Logo from "components/Logo";
import MediaMatch from "components/MediaMatch";
import UserDropdown from "components/UserDropdown";
import useHideScroll from "hooks/useHideScroll";
import { useWindowSize } from "hooks/useWindowSize";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

import MobileMenu from "./MobileMenu";
import Notifications from "./Notifications";
import SearchBox from "./SearchBox";
import * as S from "./styles";

export type LinkType = {
  href: string;
  label: string;
};

const links: LinkType[] = [
  { href: "/", label: "Home" },
  { href: "/marketplace", label: "Marketplace" },
  { href: "/collections", label: "Collections" },
  { href: "/packs", label: "Packs" },
];

export type HeaderColor = "black" | "white";

export type HeaderProps = {
  mobileColor?: HeaderColor;
  desktopColor?: HeaderColor;
  logoUrl?: string;
};

const Header = ({
  mobileColor = "black",
  desktopColor = "black",
  logoUrl,
}: HeaderProps) => {
  const { pathname } = useRouter();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileSearchBoxOpen, setIsMobileSearchBoxOpen] = useState(false);
  useHideScroll(isMobileMenuOpen);
  const { isLaptop } = useWindowSize();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((isOpen) => !isOpen);
  };

  const toggleMobileSearchBox = () => {
    setIsMobileSearchBoxOpen((isOpen) => !isOpen);
  };

  return (
    <S.Wrapper
      isOpen={isMobileMenuOpen}
      mobileColor={mobileColor}
      desktopColor={desktopColor}
      className="header"
    >
      {isMobileSearchBoxOpen && (
        <S.MobileSearchBoxContainer className="header__mobile-search-box-container">
          <SearchBox
            mobileColor={mobileColor}
            desktopColor={desktopColor}
            className="header__searchbox"
          />
          <S.MobileSearchBoxCancel
            className="header__mobile-search-box-cancel"
            role="button"
            onClick={toggleMobileSearchBox}
          >
            Cancel
          </S.MobileSearchBoxCancel>
        </S.MobileSearchBoxContainer>
      )}
      {!isMobileSearchBoxOpen && (
        <>
          <S.LeftContent className="header__left-content">
            <Logo logoUrl={logoUrl} />
            <MediaMatch greaterThan="medium" className="header__media-match">
              <SearchBox
                className="header__search-box"
                desktopColor={desktopColor}
                mobileColor={mobileColor}
              />
            </MediaMatch>
          </S.LeftContent>
          <S.RightContent className="right-content">
            <MediaMatch lessThan="medium" className="header__media-match">
              <S.IconWrapper
                onClick={toggleMobileSearchBox}
                className="header__icon-wrapper"
              >
                <Search size={"16"} className="header__search" />
              </S.IconWrapper>
            </MediaMatch>
            <S.DesktopNav className="header__desktop-nav">
              {links.map(({ href, label }) => (
                <Link key={href} href={href} passHref>
                  <S.MenuLink
                    isSelected={href === pathname}
                    desktopColor={desktopColor}
                    mobileColor={mobileColor}
                    className={`header__desktop-link ${
                      href === pathname ? "selected" : ""
                    }`}
                  >
                    {label}
                  </S.MenuLink>
                </Link>
              ))}
            </S.DesktopNav>
            <Notifications />
            <UserDropdown />
            <S.MobileNav className="header__mobile-nav">
              <S.IconWrapper onClick={toggleMobileMenu}>
                <Menu
                  aria-label="Open Menu"
                  size={"16"}
                  className="header__menu"
                />
              </S.IconWrapper>
            </S.MobileNav>
          </S.RightContent>
        </>
      )}
      {isLaptop && (
        <MobileMenu
          isOpen={isMobileMenuOpen}
          links={links}
          onClose={toggleMobileMenu}
        />
      )}
    </S.Wrapper>
  );
};

export default Header;
