import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.25 6v12a1.5 1.5 0 0 0 1.5 1.5h15a.75.75 0 0 0 .75-.75V8.25a.75.75 0 0 0-.75-.75h-15A1.5 1.5 0 0 1 4.25 6Zm0 0a1.5 1.5 0 0 1 1.5-1.5H18.5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.546 14.847 1.924-1.923L13.546 11"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 15.81a3.848 3.848 0 0 1 3.726-2.886h2.244"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
