import { useUser } from "@auth0/nextjs-auth0/client";
import { Close as CloseIcon } from "@styled-icons/material-outlined/Close";
import Image from "components/Image";
import Link from "next/link";
import s3ToCloudFront from "utils/s3ToCloudFront";

import { LinkType } from "..";
import * as S from "./styles";

export type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  links: LinkType[];
};

const MobileMenu = ({ isOpen, onClose, links }: MobileMenuProps) => {
  const { user } = useUser();

  return (
    <S.MobileMenu
      aria-hidden={!isOpen}
      isOpen={isOpen}
      className="header__mobile-menu"
    >
      <S.MobileHeader className="header__mobile-header">
        <S.MobileLoggedContent className="header__mobile-logged-content">
          {user && (
            <>
              <S.MobilePicture className="header__mobile-picture">
                {user.picture && (
                  <Image
                    src={s3ToCloudFront(user.picture)}
                    alt={`${user.username} photo`}
                    layout="fill"
                    objectFit="cover"
                    className="header__image"
                  />
                )}
              </S.MobilePicture>
              <S.MobileUserName className="header__mobile-username">
                {user.user_metadata.username}
              </S.MobileUserName>
            </>
          )}
        </S.MobileLoggedContent>
        <S.MobileCloseButton
          className="header__mobile-close-button"
          aria-label="Close Menu"
          onClick={onClose}
        >
          <CloseIcon size="16" className="header__close-icon" />
        </S.MobileCloseButton>
      </S.MobileHeader>
      {links.map(({ href, label }) => (
        <Link key={href} href={href} passHref>
          <S.MobileMenuLink className="header__mobile-link" onClick={onClose}>
            {label}
          </S.MobileMenuLink>
        </Link>
      ))}
    </S.MobileMenu>
  );
};

export default MobileMenu;
