import { DefaultTheme } from "styled-components";

import { HeaderColor } from ".";

export const getBorderColor = (
  theme: DefaultTheme,
  baseColor: HeaderColor | undefined
) => {
  if (baseColor === "black") {
    return theme.colors.gray;
  }

  return theme.colors[baseColor!];
};

export const getSearchBoxContentColor = (
  theme: DefaultTheme,
  baseColor: HeaderColor | undefined
) => {
  if (baseColor === "black") {
    return theme.colors.darkGray;
  }

  return theme.colors[baseColor!];
};

export const getDesktopLinkColor = (
  theme: DefaultTheme,
  baseColor: HeaderColor | undefined,
  isSelected: boolean
) => {
  if (baseColor === "black" && isSelected) {
    return theme.colors.red;
  }

  return theme.colors[baseColor!];
};
