import styled, { css } from "styled-components";

export const NotificationLink = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    padding: 1.2rem;
    transition: background, color, ${theme.transition.default};

    &:hover {
      background: ${theme.colors.lightGray};
      color: ${theme.colors.white};
    }
  `}
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NotificationTitle = styled.span`
  white-space: nowrap;
`;

export const NotificationText = styled.span`
  ${({ theme }) => css`
    color: #363636;
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.xsmall};
  `}
`;

export const TimespanText = styled.span`
  ${({ theme }) => css`
    padding-top: 0.5rem;
    color: #363636;
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.spacings.xsmall};
  `}
`;

export const AllNotificationsLink = styled.a`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2rem;
  `}
`;

export const NotificationIconContainer = styled.div`
  ${({ theme }) => css`
    border-radius: 50%;
    border: 1px solid ${theme.colors.gray};
    height: 4.8rem;
    width: 4.8rem;
    padding: 1.13rem;
    margin-right: ${theme.spacings.xsmall};
    line-height: 1;
    color: ${theme.colors.red};

    > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  `}
`;

export const UnreadNotificationSign = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    margin-left: ${theme.spacings.xsmall};
  `}
`;

export const EmptyNotificationList = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `}
`;
